<template>
    <div class="pay-success">
        <div class="pay-success-icon">
            <img src="../../assets/images/pay-success.png" alt="支付成功">
        </div>
        <p class="pay-success-text">
            为避免重复支付,请于付款完成后,留意订单的支付状态
        </p>
<!--        <v-btn-->
<!--            class="pay-success-btn"-->
<!--            rounded-->
<!--            depressed-->
<!--            color="primary"-->
<!--            @click="ok"-->
<!--        >-->
<!--            确定-->
<!--        </v-btn>-->
<!--        <v-btn-->
<!--            class="pay-success-btn"-->
<!--            rounded-->
<!--            depressed-->
<!--            color="primary"-->
<!--            @click="view"-->
<!--        >-->
<!--            查看订单-->
<!--        </v-btn>-->
    </div>
</template>

<script>
export default {
    name: 'PaySuccess',
    data() {
        return {

        }
    },

    methods: {
        ok() {
            localStorage.removeItem('payUser');
            this.$router.push('/index')
        },
        view() {
            const id = localStorage.getItem('payUser');
            localStorage.removeItem('payUser');
            this.$router.push({
                name: 'bill',
                query: {
                    id: id,
                    view: true
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.pay-success-icon {
    padding: 50px 8% 20px 18%;
    img {
        width: 100%;
    }
}
.pay-success-text {
    text-align: center;
    font-size: 20px;
    letter-spacing: 8px;
    margin-bottom: 32px;
}
.pay-success-btn {
    display: block;
    margin: 0 auto 24px;
    width: 80%;
    height: 46px !important;
}
</style>
